function animateCSS(element, animationName, options) {
  return new Promise(function(resolve, reject) {
    if (element.classList.contains('d-none'))
      resolve()
    else {
      const delay = (options || {}).delay || 0
      const delayClass = `delay-${delay}s`

      element.classList.add('animated', animationName, delayClass)
      element.addEventListener('animationend', handleAnimationEnd)

      function handleAnimationEnd() {
        element.classList.remove('animated', animationName, delayClass)
        element.removeEventListener('animationend', handleAnimationEnd)

        if ((options || {}).hide)
          element.classList.add('d-none')

        if ((options || {}).remove)
          element.parentNode.removeChild(element)

        resolve()
      }
    }
  })
}

export { animateCSS }

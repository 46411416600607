import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'address', 'city', 'zipCode', 'country', 'lonlat','department','region']

  connect() {
    // Force google initialization when the page loads
    if (typeof google !== 'undefined') {
      this.initGoogle()
    }
  }

  initGoogle() {
    // Setup autocomplete
    // Set search bounds center to Paris
    const center = { lat: 48.862725, lng: 2.287592 }
    const defaultBounds = {
      north: center.lat + 1,
      south: center.lat - 1,
      east: center.lng + 1,
      west: center.lng - 1,
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
      fields: ['address_component', 'formatted_address', 'geometry'],
      types: ['address'],
          componentRestrictions: { country: ['fr','ch','ca','de'] },
      bounds: defaultBounds,
      // Allow search to the whole world
      strictBounds: false
    })

    this.autocomplete.addListener('place_changed', () => this.formatAddress())
    this.inputTarget.addEventListener('keydown', () => this.resetAdress())
  }

  resetAdress() {
    this.cityTarget?.value && (this.cityTarget.value = '')
    this.addressTarget?.value && (this.addressTarget.value = '')
    this.zipCodeTarget?.value && (this.zipCodeTarget.value = '')
    this.countryTarget?.value && (this.countryTarget.value = '')
    this.lonlatTarget?.value && (this.lonlatTarget.value = '')
    this.departmentTarget?.value && (this.departmentTarget.value = '')
    this.regionTarget?.value && (this.regionTarget.value = '')
  }

  formatAddress() {
    const place = this.autocomplete.getPlace()
    if (!place?.address_components) return

    // Get each component of the address from the place details and format the address
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch(componentType) {
        case 'street_number':
          this.addressTarget.value = component.long_name
          break
        case 'route':
          this.addressTarget.value += ` ${component.long_name}`
          break
        case 'postal_code':
          this.zipCodeTarget.value = component.long_name
          break
        case 'locality':
          this.cityTarget.value = component.long_name
          break
        case 'country':
          this.countryTarget.value = component.short_name.toLowerCase()
          break
        case 'administrative_area_level_1':
          this.regionTarget.value = component.long_name.toLowerCase()
          break
        case 'administrative_area_level_2':
          this.departmentTarget.value = component.long_name.toLowerCase()
          break
        default:
          break
      }
    }

    this.inputTarget.value = place.formatted_address
    this.lonlatTarget.value = `${place.geometry.location.lng()} ${place.geometry.location.lat()}`
  }

  keydown(event) {
    // Prevent page refresh on enter (if no address was selected)
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
    }
  }
}

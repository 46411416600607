import { Controller } from 'stimulus'
import { animateCSS } from '../src/animate'

export default class extends Controller {

  connect() {
    animateCSS(this.element, 'bounce')
    .then(() => animateCSS(this.element, 'fadeOutUp', { delay: 4 }))
    .then(() => this.element.classList.add('d-none'))
  }

}

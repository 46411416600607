import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'submit', 'input']

  connect() {
    // Force google initialization when the page loads
    if (typeof google !== 'undefined') {
      this.initLocationGoogle()
    }
  }

  initLocationGoogle() {
    if (!this.inputTarget) return
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
      types: ['(regions)'],
      componentRestrictions: { country: ['fr','ch','ca','de'] },
    })
    this.autocomplete.addListener('place_changed', () => this.handleLocationChange())
    this.submitTarget?.addEventListener('click', () => this.submitSearchForm())
  }

  handleLocationChange() {
    const place = this.autocomplete.getPlace()
    if (!place.name) return
    this.inputTarget.value = place.name
    this.submitSearchForm()
  }

  submitSearchForm() {
    if (!this.inputTarget?.value || !this.formTarget) return
    this.formTarget.requestSubmit()
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'formContainer',
    'cardNumber',
    'cardExpiry',
    'cardCvc',
    'cardIcon',
    'loader',
    'errorNumber',
    'errorExpiry',
    'errorCvc',
    'errorForm',
    'submitButton',
    'paymentIntentId',
    'terms'
  ]

  displayError(error, field) {
    this[`error${field}Target`].textContent = error ? error.message : ''
  }

  setCardBrand(brand) {
    this.cardIconTarget.classList.remove('visa', 'mastercard', 'unknown')
    this.cardIconTarget.classList.add(this.cardBrands.includes(brand) ? brand : 'unknown')
  }

  connect() {
    this.cardBrands = ['visa', 'mastercard']

    /* INIT STRIPE */
    const stripe = Stripe(this.data.get('publishableKey'), { locale: 'fr' })
    const elements = stripe.elements()
    const style = {
      base: {
        color: '#2854a1',
        '::placeholder': {
          color: '#c0ccda'
        }
      },
      invalid: {
        color: '#ff5630'
      }
    }

    const cardNumber = elements.create('cardNumber', { style })
    cardNumber.mount(this.cardNumberTarget)

    const cardExpiry = elements.create('cardExpiry', { style })
    cardExpiry.mount(this.cardExpiryTarget)

    const cardCvc = elements.create('cardCvc', { style })
    cardCvc.mount(this.cardCvcTarget)

    /* EVENTS */
    cardNumber.on('ready', () => {
      // Hide loader and display form when stripe is loaded
      this.loaderTarget.classList.add('d-none')
      this.formContainerTarget.classList.remove('invisible')
    })

    cardNumber.on('change', ({ error, brand }) => {
      this.displayError(error, 'Number')
      this.setCardBrand(brand)
    })

    cardExpiry.on('change', ({ error }) => this.displayError(error, 'Expiry'))

    cardCvc.on('change', ({ error }) => this.displayError(error, 'Cvc'))

    /* HANDLE SUBMIT */
    this.element.addEventListener('submit', (event) => {
      event.preventDefault()

      if (!this.termsTarget.checked) {
        this.displayError({ message: 'Vous devez accepter les conditions générales.' }, 'Form')
      } else {
        this.submitButtonTarget.dispatchEvent(new Event('button.disable'))

        stripe.confirmCardPayment(this.data.get('clientSecret'), {
          payment_method: {
            card: cardNumber,
            billing_details: {
              name: this.data.get('clientName'),
              email: this.data.get('clientEmail'),
              address: this.data.get('clientAddress'),
              phone: this.data.get('clientPhone')
            }
          }
        }).then(result => {
          setTimeout(() => {
            this.submitButtonTarget.dispatchEvent(new Event('button.enable'))
          }, 400)

          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.displayError(result.error, 'Form')
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // pass the payment reference to the controller
              this.paymentIntentIdTarget.value = result.paymentIntent.id

              // post the form
              this.element.submit()

              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
            }
          }
        })
      }
    })
  }
}
